// extracted by mini-css-extract-plugin
export var ammoCaseStudyVideo = "bL_mq";
export var caseContactFormSection = "bL_k4";
export var caseSolutionRing = "bL_mr";
export var caseStudyBackground__bgColor = "bL_kV";
export var caseStudyBackground__lineColor = "bL_kW";
export var caseStudyCta__bgColor = "bL_l0";
export var caseStudyHead = "bL_k6";
export var caseStudyHead__imageWrapper = "bL_kT";
export var caseStudyProjectsSection = "bL_k5";
export var caseStudyQuote__dark = "bL_mm";
export var caseStudyQuote__light = "bL_mn";
export var caseStudySolution__ringOne = "bL_mp";
export var caseStudySolution__ringTwo = "bL_k0";
export var caseStudyTechSection = "bL_lb";
export var caseStudyVideo__ring = "bL_k8";
export var caseStudy__bgDark = "bL_kS";
export var caseStudy__bgLight = "bL_kR";